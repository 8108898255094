
import { defineComponent, ref, computed, nextTick } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import Q from 'q';
import { generalStore } from '@/store';
import {
    BusinessCashPayment,
    BusinessCheckPayment,
    BusinessBankTransferPayment,
    BusinessCreditCardPelecardPayment
} from '@/models/BusinessClients';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import { ReceiptPaymentSteps, CreditCardPaymentType, ToOptions } from '@/models/Enums';
import { useI18n } from 'vue-i18n';
import { Currency, CurrencyRate, AddPaymentInReceiptViewModel, IdName, ProviderAccountObject } from '@/models/Interfaces';
import mixin from '@/mixin';

export default defineComponent({
    components: {
        OverlayModal,
        Calendar,
        Multiselect,
        InputNumber
    },
    props: {
        paymentCreditCardExist: {
            type: Boolean,
            default: false
        },
        localCurrencyId: {
            type: String,
            default: ''
        },
        localCurrencySymbol: {
            type: String,
            required: true
        },
        isTerminalPaymentOnline: {
            type: Boolean,
            required: false
        },
        canBeNegative: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    name: 'BusinessReceiptPaymentModal',
    async setup(props, { emit }: any) {
        const banks = ref<IdName[]>([]);
        const providerAccounts = ref<ProviderAccountObject[]>([]);
        const creditCardPaymentTypes = ToOptions(CreditCardPaymentType);
        const creditCardPelecardPayment = ref(new BusinessCreditCardPelecardPayment());
        const cashPayment = ref(new BusinessCashPayment());
        const checkPayment = ref(new BusinessCheckPayment());
        const bankTransferPayment = ref(new BusinessBankTransferPayment());

        const currencyRates = computed<CurrencyRate[]>(() => generalStore.getters.currencyRates);
        const providerAccountsByCurrency = computed<ProviderAccountObject[]>(() =>
            providerAccounts.value.filter(x => x.currencies.includes(bankTransferPayment.value.currencyId))
        );
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const receiptPaymentSteps = ReceiptPaymentSteps;
        const selectedStep = ref(receiptPaymentSteps.SelectType);
        const maxWidth = window.innerWidth;
        const isOpen = ref(false);
        const { t } = useI18n();
        const currenciesSelect = {
            valueProp: 'id',
            trackBy: 'name',
            label: 'symbol',
            options: currencies,
            canDeselect: false
        };
        const creditCardPaymentTypesSelect = {
            valueProp: 'id',
            trackBy: 'id',
            label: 'name',
            options: creditCardPaymentTypes,
            canDeselect: false
        };
        const totalInLocal = computed(() => {
            if (selectedStep.value == ReceiptPaymentSteps.CreditCard) {
                return (creditCardPelecardPayment.value.currencyRate || 0) * (creditCardPelecardPayment.value.total || 0);
            } else if (selectedStep.value == ReceiptPaymentSteps.Cash) {
                return (cashPayment.value.currencyRate || 0) * (cashPayment.value.total || 0);
            } else if (selectedStep.value == ReceiptPaymentSteps.Check) {
                return (checkPayment.value.currencyRate || 0) * (checkPayment.value.total || 0);
            } else if (selectedStep.value == ReceiptPaymentSteps.BankTransfer) {
                return (bankTransferPayment.value.currencyRate || 0) * (bankTransferPayment.value.total || 0);
            }
            return 0;
        });

        function changeSelectedStep(step: ReceiptPaymentSteps) {
            if (step == ReceiptPaymentSteps.CreditCard && props.paymentCreditCardExist) {
                return;
            }
            selectedStep.value = step;
        }

        const currencyChanged = async () => {
            await nextTick();
            if (selectedStep.value == ReceiptPaymentSteps.CreditCard) {
                creditCardPelecardPayment.value.currencyRate = currencyRates.value.filter(
                    c => c.currencyId == creditCardPelecardPayment.value.currencyId
                )?.[0].rate;
            } else if (selectedStep.value == ReceiptPaymentSteps.Cash) {
                cashPayment.value.currencyRate = currencyRates.value.filter(c => c.currencyId == cashPayment.value.currencyId)?.[0].rate;
            } else if (selectedStep.value == ReceiptPaymentSteps.Check) {
                checkPayment.value.currencyRate = currencyRates.value.filter(c => c.currencyId == checkPayment.value.currencyId)?.[0].rate;
            } else if (selectedStep.value == ReceiptPaymentSteps.BankTransfer) {
                bankTransferPayment.value.currencyRate = currencyRates.value.filter(
                    c => c.currencyId == bankTransferPayment.value.currencyId
                )?.[0].rate;
                bankTransferPayment.value.providerAccountId = '';
                bankTransferPayment.value.providerAccountName = '';
            }
        };

        async function open(openReq: AddPaymentInReceiptViewModel | null = null) {
            swal.showLoading();
            banks.value = await generalStore.dispatch('getBanks');
            providerAccounts.value = await generalStore.dispatch('getProviderAccounts');
            selectedStep.value = ReceiptPaymentSteps.SelectType;
            creditCardPelecardPayment.value = new BusinessCreditCardPelecardPayment();
            creditCardPelecardPayment.value.currencyId = props.localCurrencyId;
            creditCardPelecardPayment.value.isTerminalPaymentOnline = props.isTerminalPaymentOnline;
            cashPayment.value = new BusinessCashPayment();
            cashPayment.value.currencyId = props.localCurrencyId;
            checkPayment.value = new BusinessCheckPayment();
            checkPayment.value.currencyId = props.localCurrencyId;
            bankTransferPayment.value = new BusinessBankTransferPayment();
            bankTransferPayment.value.currencyId = props.localCurrencyId;

            if (openReq != null) {
                selectedStep.value = openReq.selectedStep;
                if (selectedStep.value == ReceiptPaymentSteps.CreditCard) {
                    creditCardPelecardPayment.value = openReq.creditCardPelecardPayment;
                } else if (selectedStep.value == ReceiptPaymentSteps.Cash) {
                    cashPayment.value = openReq.cashPayment;
                } else if (selectedStep.value == ReceiptPaymentSteps.Check) {
                    checkPayment.value = openReq.checkPayment;
                } else if (selectedStep.value == ReceiptPaymentSteps.BankTransfer) {
                    bankTransferPayment.value = openReq.bankTransferPayment;
                }
            }
            await nextTick();
            await Q.delay(400);
            isOpen.value = true;
            swal.close();
        }
        function close() {
            const element = document.getElementById('businessReceiptsPayments');
            if (element) {
                element.classList.remove('was-validated');
            }
            isOpen.value = false;
        }
        function addPaymentCreditCard() {
            const req: AddPaymentInReceiptViewModel = {
                creditCardPelecardPayment: creditCardPelecardPayment.value,
                selectedStep: ReceiptPaymentSteps.CreditCard,
                cashPayment: new BusinessCashPayment(),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: new BusinessBankTransferPayment()
            };
            emit('addPaymentInReceipt', req);
        }
        function addPaymentCash() {
            const req: AddPaymentInReceiptViewModel = {
                selectedStep: selectedStep.value,
                cashPayment: cashPayment.value,
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: new BusinessBankTransferPayment(),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            emit('addPaymentInReceipt', req);
        }
        function addPaymentCheck() {
            const checkPaymentToAdd: BusinessCheckPayment = JSON.parse(JSON.stringify(checkPayment.value));
            checkPaymentToAdd.bankName = banks.value.find(x => x.id == checkPayment.value.bankId)?.name || '';
            const req: AddPaymentInReceiptViewModel = {
                selectedStep: selectedStep.value,
                cashPayment: new BusinessCashPayment(),
                checkPayment: checkPaymentToAdd,
                bankTransferPayment: new BusinessBankTransferPayment(),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            emit('addPaymentInReceipt', req);
        }
        function addPaymentBankTransfer() {
            const bankTransferPaymentToAdd: BusinessBankTransferPayment = JSON.parse(JSON.stringify(bankTransferPayment.value));
            bankTransferPaymentToAdd.bankName = banks.value.find(x => x.id == bankTransferPayment.value.bankId)?.name || '';
            const req: AddPaymentInReceiptViewModel = {
                selectedStep: selectedStep.value,
                cashPayment: new BusinessCashPayment(),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: bankTransferPaymentToAdd,
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            emit('addPaymentInReceipt', req);
        }
        function addPayment() {
            if (selectedStep.value == ReceiptPaymentSteps.CreditCard) {
                if (creditCardPelecardPayment.value.isTerminalPaymentOnline) {
                    return;
                }
                addPaymentCreditCard();
            } else if (selectedStep.value == ReceiptPaymentSteps.Cash) {
                addPaymentCash();
            } else if (selectedStep.value == ReceiptPaymentSteps.Check) {
                addPaymentCheck();
            } else if (selectedStep.value == ReceiptPaymentSteps.BankTransfer) {
                addPaymentBankTransfer();
            }
            close();
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'info',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                return;
            } else {
                if (selectedStep.value == ReceiptPaymentSteps.CreditCard) {
                    if (creditCardPelecardPayment.value.total == 0) {
                        return;
                    }
                    if (creditCardPelecardPayment.value.isTerminalPaymentOnline) {
                        emit('clearCreditCard', creditCardPelecardPayment.value);
                    }
                } else if (selectedStep.value == ReceiptPaymentSteps.Cash) {
                    if (cashPayment.value.total == 0) {
                        return;
                    }
                } else if (selectedStep.value == ReceiptPaymentSteps.Check) {
                    if (checkPayment.value.total == 0) {
                        return;
                    }
                } else if (selectedStep.value == ReceiptPaymentSteps.BankTransfer) {
                    if (bankTransferPayment.value.total == 0) {
                        return;
                    }
                }
                addPayment();
            }
        };

        function checkIsNumber(evt: Event) {
            const value = (evt.target as HTMLTextAreaElement).value;
            const regex = /^\d+$/;
            const isDigits = regex.test(value);
            if (isDigits) {
                return;
            }

            creditCardPelecardPayment.value.lastFourDigits = '';
        }

        function checkPaymentIsNumber(filedType: string, evt: Event) {
            let res = 0;
            const target = evt.target as HTMLTextAreaElement;
            res = parseInt(target.value);
            if (filedType === 'accountNumber') {
                if (isNaN(res)) {
                    checkPayment.value.accountNumber = '';
                } else {
                    checkPayment.value.accountNumber = res.toString();
                }
            } else if (filedType === 'checkNumber') {
                if (isNaN(res)) {
                    checkPayment.value.checkNumber = '';
                } else {
                    checkPayment.value.checkNumber = res.toString();
                }
            }
        }

        function bankTransferPaymentIsNumber(filedType: string, evt: Event) {
            let res = 0;
            const target = evt.target as HTMLTextAreaElement;
            res = parseInt(target.value);
            if (filedType === 'accountNumber') {
                if (isNaN(res)) {
                    bankTransferPayment.value.accountNumber = '';
                } else {
                    bankTransferPayment.value.accountNumber = res.toString();
                }
            }
        }

        function KeypressNumber(event: KeyboardEvent) {
            if (props.canBeNegative) {
                return mixin.methods.filterKeyOnlyNumbersDotMinus(event);
            } else {
                return mixin.methods.filterKeyOnlyNumbersDot(event);
            }
        }

        function providerAccountChanged(event: string) {
            const providerAccountName = providerAccounts.value.find(x => x.id == event)?.name || '';
            bankTransferPayment.value.providerAccountName = providerAccountName;
        }

        return {
            submitForm,
            maxWidth,
            providerAccountChanged,
            providerAccounts,
            isOpen,
            open,
            close,
            receiptPaymentSteps,
            selectedStep,
            currenciesSelect,
            cashPayment,
            checkPayment,
            bankTransferPayment,
            currencyChanged,
            totalInLocal,
            checkIsNumber,
            banks,
            checkPaymentIsNumber,
            bankTransferPaymentIsNumber,
            changeSelectedStep,
            creditCardPelecardPayment,
            creditCardPaymentTypesSelect,
            KeypressNumber,
            providerAccountsByCurrency
        };
    }
});
